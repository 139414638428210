// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/toasts.ts"
);
import.meta.hot.lastModified = "1726719770000";
}
// REMIX HMR END

import toast from 'react-hot-toast';

const toastStyles = {
  success: {
    className:
      'p-1 text-md w-full md:min-w-[300px] !bg-primary-400 !text-white',
  },
  error: {
    className: 'p-1 text-md w-full md:min-w-[300px] !bg-error-400 !text-white',
  },
  info: {
    className: 'p-1 text-md w-full md:min-w-[300px] !bg-info-400 !text-white',
  },
  warning: {
    className:
      'p-1 text-md w-full md:min-w-[300px] !bg-warning-400 !text-white',
  },
};

export const showToast = (
  message: any,
  type: 'success' | 'error' = 'success',
  duration: number = 5000,
) => {
  toast[type](message, {
    duration: duration,
    position: 'top-center',
    className: toastStyles[type].className,
    icon: null,
  });
};
